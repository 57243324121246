<template>
  <div class="city_select_box">
    <el-select style="width:150px;" v-model="info.province" placeholder="请选择省">
      <el-option v-for="item in province_options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <el-select style="width:150px;" v-model="info.city" placeholder="请选择市">
      <el-option v-for="item in city_options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <el-select style="width:150px;" v-model="info.area" placeholder="请选择区">
      <el-option v-for="item in area_options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  // import { getToken } from "@/utils/auth";
  export default {
    props: {
      info: {
        type: Object,
        default: {},
      },
    },
    watch: {
      info: {
        handler: function(val) {
          console.log(val, '-----------')
        },
        immediate: true, //第一次刷新页面时就会执行
      },
    },
    data() {
      return {
        province_options:[],//省份列表
        city_options:[],//城市列表
        area_options:[],//区域列表
      }
    },
    mounted() {

    },
    methods: {},
  }
</script>
<style type="text/css">
 .city_select_box{
   display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
 }
</style>
