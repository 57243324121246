import request from '@/utils/request'
// 新闻列表
export function Order_printStatus(data) {
  // 打印成功回调
  return request({
    url: 'admin/Order/printStatus',
    method: 'post',
    data,
  })
  // 包裹打印验证
}

export function Order_printCheck(data) {
  // 打印成功回调
  return request({
    url: 'admin/Order/printCheck',
    method: 'post',
    data,
  })
}

// 称重机面单打印校验
export function check_order_print(data) {
  // 打印成功回调
  return request({
    url: 'index/Api/check_order_print',
    method: 'post',
    data,
  })
}
