<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <!-- 所属用户 -->
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="95px"
      @submit.native.prevent
    >
      <!-- 包裹单号 -->
      <el-form-item label="包裹单号" prop="code">
        <el-input
          ref="code1"
          v-model.trim="form.code"
          autocomplete="off"
          clearable
          @keyup.enter.native="handleQuery"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-table :data="gridData">
      <el-table-column property="code" label="包裹单号"></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click.native.prevent="deleteRow(scope.$index, gridData)"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">打印面单</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { Order_codePrintCheck } from '@/api/xgcApi'
  export default {
    name: 'Scan',
    components: {},
    data() {
      return {
        title: '',
        gridData: [],
        visible: false,
        loading: false,
        form: {
          code: '',
        },
        dialogFormVisible: false,
        rules: {
          code: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入包裹单号',
            },
          ],
        },
      }
    },
    methods: {
      // 初始化请求数据
      async showEdit(row) {
        this.title = '扫描单号'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs.code1.focus()
        })
      },
      // 移除table数据
      deleteRow(index, rows) {
        rows.splice(index, 1)
      },
      // 处理回车键按下事件
      handleQuery(event) {
        var data = {
          code: this.form.code,
        }
        this.gridData.push(data)
        this.form.code = ''
      },
      // 关闭弹窗
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        for (var key in this.form) {
          this.form[key] = null
        }
        this.dialogFormVisible = false
      },

      // 打印面单
      save() {
        let date = []
        this.gridData.forEach((item, index) => {
          date.push(item.code)
        })
        Order_codePrintCheck({ code: date.toString() }).then((res) => {
          if (res.code == 200) {
            this.$baseMessage(res.msg, 'success')
            this.$emit('change', res.data)
            this.gridData = []
            this.$nextTick(() => {
              this.$refs.code1.focus()
            })
            // this.close()
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  :deep .el-input--small .el-input__inner {
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
