<template>
  <el-dialog
    title="称重机打印"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <!-- 所属用户 -->
    <el-form ref="form" :model="form" label-width="95px" @submit.native.prevent>
      <!-- 包裹单号 -->
      <el-form-item label="包裹单号">
        <el-input
          ref="code1"
          v-model.trim="form.code"
          autocomplete="off"
          clearable
          @keyup.enter.native="handleQuery"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { Form } from 'element-ui'
  import { Order_codePrintCheck } from '@/api/xgcApi'
  export default {
    name: 'Scan',
    components: {},
    data() {
      return {
        title: '',
        visible: false,
        loading: false,
        form: {
          code: '',
        },
        dialogFormVisible: false,
      }
    },
    methods: {
      // 初始化请求数据
      async showEdit(row) {
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs.code1.focus()
        })
      },

      // 处理回车键按下事件
      handleQuery(event) {
        if (this.form.code) {
          this.$emit('change', this.form.code)
          this.form.code = ''
          this.$nextTick(() => {
            this.$refs.code1.focus()
          })
        } else {
          this.$nextTick(() => {
            this.$refs.code1.focus()
          })
        }
      },
      // 关闭弹窗
      close() {
        this.$refs['form'].resetFields()
        this.form.code = ''
        this.dialogFormVisible = false
      },
    },
  }
</script>
<style scoped lang="scss">
  :deep .el-input--small .el-input__inner {
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
