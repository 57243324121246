<template>
  <!-- 商品管理————组件 -->
  <el-dialog :title="title" :visible.sync="dialogFormVisible" :fullscreen="true" top="2vh" width="90%" @close="close">
    <div class="div_form">
      <el-form ref="form"  :model="form" :rules="rules" label-width="80px">
          <el-form-item label="商品名称" prop="name">
            <el-input v-model.trim="form.name" autocomplete="off" maxlength="15" show-word-limit></el-input>
          </el-form-item>
          <!-- 所属状态 -->
          <el-form-item label="商品类型" prop="type">
            <el-select style="width: 100%;" v-model="form.type" placeholder="请选择">
              <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 商品分类 -->
          <el-form-item label="商品分类" prop="cid">
            <el-select style="width: 100%;"  v-model="form.cid" placeholder="请选择">
              <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品品牌" prop="bid">
            <el-select
                style="width: 100%;"
                v-model="form.bid"
                filterable
                @change="bidChange"
                placeholder="请选择商品品牌">
                <el-option
                  v-for="item in bidList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="品牌类型" prop="b_type_id">
            <el-select @change="brand_typeChange" style="width: 100%;"  v-model="form.b_type_id" placeholder="请选择">
              <el-option v-for="(item , index) in brand_typeList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- 商品金额 -->
          <el-form-item label="商品金额" prop="price">
            <el-input v-model.trim="form.price" autocomplete="off" maxlength="8" show-word-limit
              @input="handleInput"></el-input>
          </el-form-item>
          <el-form-item v-if="isShow" label="添加时间" prop="update_time">
            <el-input v-model.trim="form.update_time" :disabled="true" autocomplete="off"></el-input>
          </el-form-item>
          <!-- 产品图片 -->
          <el-form-item label="产品图片" prop="pic">
            <uploadFile :limit="5" :img-list-string="form.pic" @fileChange="uploadChange($event, 'pic')" />
          </el-form-item>
          <el-form-item label="是否推荐" prop="recommend">
            <el-radio-group v-model="form.recommend">
              <el-radio :label="0">不推荐</el-radio>
              <el-radio :label="1">推荐</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="商品详情" prop="content">
            <div class="editor-wrapper">
              <!-- 改进quill的图片base64功能，改为上传后拼接url，上传组件隐藏 -->
              <!-- :on-success="handleQuillImgSuccess" -->
              <el-upload class="quill-upload" :http-request="upload" action="#" :show-file-list="false" with-credentials
                accept="image/gif, image/jpeg, image/jpg, image/bmp, image/png" style="display: none">
                <i id="imgInput" class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <quill-editor ref="myQuillEditor" v-model="value" :options="editorOption" @change="onEditorChange($event)" />
            </div>
          </el-form-item>
          <el-form-item label="商品规格" prop="recommend">

          </el-form-item>
          <el-form-item>
            <div v-for="(item,index) in SpecificationsList" :key="index" class="guige_item">
              <div class="guige_item_top">
                <div style="display: flex;">
                  <el-input @input="changeInput_sku($event , index )" v-model="item.name" style="width: 300px;margin-right: 15px;"  placeholder="请输入规格名" autocomplete="off"></el-input>
                  <el-button type="primary" @click="addSpecifications_item(index)">添加规格项</el-button>
                </div>
                <el-button type="text" style="color:red;"  @click="delSpecifications(index)" >删除</el-button>
              </div>
              <div class="guige_item_bottom">
                <el-input v-model="ite.text" @input="changeInput($event , index ,ind)"  v-for="(ite,ind) in item.item" :key="ind" style="width: 250px;margin-right: 15px;"  placeholder="请输入规格项" autocomplete="off">
                  <el-button slot="append"  @click="delSpecifications_item(index , ind)" icon="el-icon-error"></el-button>
                </el-input>
              </div>
            </div>
            <div>
              <el-button type="primary" @click="addSpecifications">添加规格</el-button>
              <el-button type="primary" @click="getSkuList">生成规格列表</el-button>
            </div>

          </el-form-item>
          <el-form-item label="SKU列表" v-if="skuList.length>0">
              <el-table :data="skuList" border style="width: 100%">
                <el-table-column  align="left" prop="name" label="规格名" min-width="120"> </el-table-column>
                <el-table-column align="center" prop="number" label="规格图" min-width="120">
                  <template slot-scope="scope">
                    <div style="width: 100%; display: flex; justify-content: center;">
                      <uploadFile :limit="1" maxlength="15" show-word-limit :img-list-string="scope.row.pic" @fileChange="skuListPicChange($event, scope.$index)" />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="number" label="库存" min-width="120">
                  <template slot-scope="{row}">
                      <el-input maxlength="10"   v-model="row.stock" show-word-limit></el-input>
                  </template>

                </el-table-column>
              </el-table>
          </el-form-item>



        </el-form>

    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
    </el-dialog>

</template>

<script>
  import {
    goodsUpdate,
    goodsDetail,
    GoodsCate_list,
    index_brand,
    index_brand_types
  } from '@/api/xgcApi'
  import {
    getUploadUrl
  } from '@/config'
  import uploadFile from '@/components/uploadFile'
  import {
    goodsAdd
  } from '../../../api/xgcApi'
  import exoprt_file from '@/utils/expor_file' // 导出excel文件
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{
      header: 1
    }, {
      header: 2
    }], // custom button values
    [{
      list: 'ordered'
    }, {
      list: 'bullet'
    }],
    [{
      script: 'sub'
    }, {
      script: 'super'
    }], // superscript/subscript
    [{
      indent: '-1'
    }, {
      indent: '+1'
    }], // outdent/indent
    [{
      direction: 'rtl'
    }], // text direction

    [{
      size: ['small', false, 'large', 'huge']
    }], // custom dropdown
    [{
      header: [1, 2, 3, 4, 5, 6, false]
    }],

    [{
      color: []
    }, {
      background: []
    }], // dropdown with defaults from theme
    [{
      font: []
    }],
    [{
      align: []
    }],
    ['clean'], // remove formatting button
    ['image'],
  ]

  export default {
    name: 'UserManagementEdit',
    components: {
      uploadFile,
    },
    props: {
      typeList: {
        type: Array,
        default: [],
      },
    },
    data() {
      return {
        value: '',
        editorOption: {
          placeholder: '请输入内容',
          modules: {
            toolbar: {
              container: toolbarOptions,
              handlers: {
                // 重写点击组件上的图片按钮要执行的代码
                image: function(value) {
                  document
                    .querySelector('.quill-upload .el-upload__input')
                    .click()
                },
              },
            },
          },
        },
        upUrl: '/index/index/file', //上传图片的接口
        editor: null,
        // 规格列表
        SpecificationsList:[

                    // {
                    //   name:'内存',
                    //   item:[{text:'128GB'} , {text:'256GB'}  , {text:'1TB'}]
                    // },
                    // {
                    //   name:'颜色',
                    //   item:[{text:'白色'} , {text:'粉色'} , {text:'远山蓝'}]
                    // }

        ],
        skuList:[], //规格列表
        form: {
          b_type_id:null
        },
        bidList:[],//商品品牌列表
        brand_typeList:[],//品牌类型列表
        dialogFormVisible: false,
        rules: {
          id: [{
            required: true,
            trigger: 'blur',
            message: '请输入商品id'
          }],
          cid: [
            { required: true, trigger: 'blur', message: '请选择商品分类' },
            { required: true, validator: (rule, value, callback)=>{
              if (value==0) {
                callback(new Error('请选择商品分类'));
              } else {
                callback();
              }
            }, trigger: 'blur' }
          ],
          bid: [
            { required: true, trigger: 'blur', message: '请选择商品品牌' },
            { required: true, validator: (rule, value, callback)=>{
              if (value==0 || value==null || value == '') {
                callback(new Error('请选择商品品牌'));
              } else {
                callback();
              }
            }, trigger: 'blur' }
          ],
          b_type_id: [
            { required: true, trigger: 'blur', message: '请选择品牌类型' },
            { required: true, validator: (rule, value, callback)=>{
              if (value==0 || value==null || value == '') {
                callback(new Error('请选择品牌类型'));
              } else {
                callback();
              }
            }, trigger: 'blur' }
          ],
          name: [{
            required: true,
            trigger: 'blur',
            message: '请输入商品名称'
          }, ],

          price: [{
            required: true,
            trigger: 'blur',
            message: '请输入商品金额'
          }, ],
          // type: [
          //   { required: true, trigger: 'change', message: '请选择商品类型' },
          // ],
          // pic: [{ required: true, trigger: 'blur', message: '请选择商品图片' }],
        },
        title: '',
        isShow: false,
        statusList: [
          // {
          //   value: 0,
          //   label: '快递费',
          // },
          {
            value: 1,
            label: '补价商品',
          },
          {
            value: 2,
            label: '代买商品',
          },
        ],
      }
    },

    created() {},
    mounted() {
      this.getBidList()
    },
    methods: {
      getBidList(){
        index_brand({}).then(res=>{
          console.log(res)
          this.bidList = res.data
        })
      },
      bidChange(e){
        this.form.bid = e
        this.form.b_type_id = ''
        index_brand_types({brand_id:e}).then(res=>{
          this.brand_typeList = res.data
        })
      },
      brand_typeChange(e){
        console.log(e)
        this.form.b_type_id = e
      },
      // 将-字符换成_
      changeInput_sku(e , index){
        this.SpecificationsList[index].name = this.SpecificationsList[index].name.replace(/-/g, '_')
      },
      // 将-字符换成_
      changeInput(e , index , ind){
        this.SpecificationsList[index].item[ind].text = this.SpecificationsList[index].item[ind].text.replace(/-/g, '_')
      },
      // t添加规格项
      addSpecifications(){
        var item = {
          name:'',
          item:[]
        }
        this.SpecificationsList.push(item)
      },
      skuListPicChange(e , index){
        if(e.length>0){
          this.skuList[index].pic = e[0]
        }else{
          this.skuList[index].pic = ''

        }

      },
      // 添加规格项
      addSpecifications_item(index){
        var child = {
          text:''
        }
        this.SpecificationsList[index].item.push(child)
      },
      // 删除规格
      delSpecifications(index){
        this.SpecificationsList.splice(index , 1)
      },
      // 删除规格项
      delSpecifications_item(index , ind){
        this.SpecificationsList[index].item.splice(ind , 1)
      },
      // 生成SKU列表
      getSkuList(){
         return new Promise((resolve, reject) => {
           var arr = []
           var ISok = true
           if(this.SpecificationsList.length == 0){
             ISok=false
             this.$message({
               type:'error',
               message:'请添加规格'
             })
             reject('err')
             return
           }else{
             ISok=true
           }
           this.SpecificationsList.forEach((item,index)=>{
             var sku_item = []
             item.item.forEach((ite,ind)=>{
               if(ite.text){
                 sku_item.push(ite.text)
               }else{
                 ISok=false
                 this.$message({
                   type:'error',
                   message:'规格项不能为空'
                 })
                 reject('err')
                 return
               }
             })
             arr.push(sku_item)
           })
           if(!ISok){
             return
           }
           var sku_list = []
           if(arr.length>1){
             var sku_list_one = this.cartesianProduct(arr)
             sku_list_one.forEach((item,index)=>{
               sku_list[index] = {
                 name:item.join('-'),
                 sku:item,
                 stock:0,
                 pic:''
               }
             })
           }else{
              this.SpecificationsList[0].item.forEach((item,index)=>{
                sku_list[index] = {
                  name:item.text,
                  sku:item,
                  stock:0,
                  pic:''
                }
              })
           }
           console.log(sku_list)
           if(this.skuList.length>0){
             sku_list = sku_list.map(aItem => {
               var bItem  = this.skuList.find(bItem => bItem.name === aItem.name);
               return { ...aItem, ...bItem };
             });
             this.skuList = sku_list
           }else{
             this.skuList = sku_list
           }
           resolve(this.skuList)
           return
         })
      },
      // 迪卡儿积算法
      cartesianProduct(sets) {
        return sets.reduce((a, b) => {
          return a.reduce((res, aItem) => {
            return res.concat(b.map(bItem => [].concat(aItem, bItem)));
          }, []);
        });
      },
      /**
       *@name 富文本编辑器
       *@time 2024年4月23日15:01:58
       */
      // ---------------------------富文本编辑器开始--------------------------------------
      onEditorChange(e) {},
      upload(file) {
        // 图片上传
        file = file.file
        var data = {
          file: file,
        }
        var url = ''
        exoprt_file(this.upUrl, data).then((res) => {
          var pic = getUploadUrl + res
          let quill = this.$refs.myQuillEditor.quill
          // 获取光标所在位置
          let length = quill.getSelection().index
          // 插入图片 res.url为服务器返回的图片地址
          quill.insertEmbed(length, 'image', pic)
          // 调整光标到最后
          quill.setSelection(length + 1)
          // 调用编辑器的 insertEmbed 方法，插入URL
        })
      },
      handleInput() {
        // 移除非数字和非小数点字符，保留小数点
        this.form.price = this.form.price
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },
      // 上传图片
      uploadChange(e) {
        var strurl = e.join(',')
        strurl = strurl.replace('\\\\', '\/\/')
        strurl = strurl.replace('\\', '\/')
        strurl = strurl.replace('\\', '\/')
        this.form.pic = strurl
      },
      async showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.isShow = false
          this.form.pic = ''
          this.value = ''
        } else {
          this.title = '编辑'
          this.isShow = true
          const { data } = await goodsDetail({ id: row.id })
          this.value = data.content
          data.spec_list.forEach((item,index)=>{
            data.spec_list[index].sku = item.sku.split(',')
            data.spec_list[index].name = data.spec_list[index].sku.join('-')
          })
           data.spec.forEach((ite,index)=>{
             var arr = JSON.parse(ite.item)
             var arr_item = []
             arr.forEach((ite,ind)=>{
               arr_item.push({
                 text:ite
               })
             })
             data.spec[index].item = arr_item
           })
          this.skuList = data.spec_list
          this.SpecificationsList = data.spec
          console.log(this.skuList)
          this.form = data
          index_brand_types({brand_id:data.bid}).then(res=>{
            this.brand_typeList = res.data
          })
        }
        this.dialogFormVisible = true
      },

      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.skuList = []
        this.SpecificationsList = []
        this.dialogFormVisible = false
        this.value = ''
      },
      save() {
        this.getSkuList().then(res=>{
          console.log(1111)
          var spec_submit = JSON.parse(JSON.stringify(this.SpecificationsList))
          spec_submit.forEach((value , index)=>{
            spec_submit[index].item_text = []
            value.item.forEach((ite,ind)=>{
              spec_submit[index].item_text.push(ite.text)
            })
            spec_submit[index].item = spec_submit[index].item_text
            delete spec_submit[index].item_text
          })
          var spec_list = JSON.parse(JSON.stringify(this.skuList))
          spec_list.forEach((item,index)=>{
            delete spec_list[index].name
          })
          console.log(2222)
          this.form.content = this.value
          this.form.spec = spec_submit
          this.form.spec_list = spec_list
          if (this.isShow == false) {
            console.log(3333)
            this.$refs['form'].validate(async (valid) => {
              if (valid) {
                console.log(this.form , '提交表单')
                const {
                  msg
                } = await goodsAdd(this.form)
                this.$baseMessage(msg, 'success')
                this.$emit('fetch-data')
                this.close()
              } else {

                return false
              }
            })
          } else {
            this.$refs['form'].validate(async (valid) => {
              if (valid) {
                console.log(this.form , '提交表单')
                const { msg } = await goodsUpdate(this.form)
                this.$baseMessage(msg, 'success')
                this.$emit('fetch-data')
                this.close()
              } else {
                return false
              }
            })
          }
        }).catch(err=>{
          console.log(err , '提交返回')
        })
      }
    },
  }
</script>
<style scoped lang="scss">
  .guige_item{
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 15px;
    margin-bottom: 20px;
  }
  .guige_item_top{
    display:flex;
    align-items: center;
    justify-content: space-between;
  }
  .guige_item_bottom{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  :deep .el-input--small .el-input__inner {
    width: 100%;
  }
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

/*  :deep .el-dialog__body {
    height: 63vh;
    overflow: hidden;
    overflow-y: scroll;
  } */
  .div_form{
    height: 80vh;
    overflow: hidden;
    overflow-y: scroll;
  }
  :deep .ql-container {
    height: 400px;
  }
</style>
