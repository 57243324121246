<template>
  <!-- 轮播图————组件 -->
  <div>
    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="500px" @close="close">
      <el-form ref="form" class="adminForm" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="类型">
          <el-radio-group v-model="form.type">
            <el-radio :label="0">韩到中配置</el-radio>
            <el-radio :label="1">中到韩配置</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="标题" prop="name">
          <el-input v-model="form.name" autocomplete="off" placeholder="请输入标题" maxlength="15"
            show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="跳转路径">
          <el-input placeholder="请输入内容" v-model="form.link">
            <template slot="append">
              <div @click="shopOpen" style="cursor: pointer; user-select: none;">
                选择商品
              </div>
            </template>
          </el-input>
          <!-- <el-input v-model="form.name" autocomplete="off" placeholder="请输入标题" maxlength="15" show-word-limit></el-input> -->
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="form.sort" placeholder="请输入内容" maxlength="3" show-word-limit
            @input="handleInput"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="file">
          <uploadFile :limt="1" :img-list-string="form.file" @fileChange="uploadChange($event, 'file')" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="商品" top="5vh" :visible.sync="shopListShow" width="70vw" @close="shopclose">
      <vab-query-form>
        <el-form ref="form" :model="queryForm" :inline="true" @submit.native.prevent>
          <el-form-item label="商品名称">
            <el-input v-model="queryForm.name" :clearable="true" placeholder="请输入商品名称" />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" native-type="submit" @click="handleQuery">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form>
      <el-table ref="tableSort" v-loading="listLoading" :data="list" element-loading-text="加载中" height="65vh" border>
        <el-table-column show-overflow-tooltip label="序号" width="120" align="center">
          <template #default="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <!-- 商品类型 -->
        <el-table-column show-overflow-tooltip prop="type" label="商品类型" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.type == 1">补价商品</div>
            <div v-if="row.type == 2">代买商品</div>
          </template>
        </el-table-column>

        <!-- 商品名称 -->
        <el-table-column show-overflow-tooltip prop="name" label="商品名称" align="center"></el-table-column>
        <!-- 商品名称 -->
        <el-table-column show-overflow-tooltip prop="price" label="商品金额" align="center"></el-table-column>
        <!-- 商品图片 -->
        <el-table-column show-overflow-tooltip label="商品图片" align="center">
          <template #default="{ row }">
            <el-image :src="row.pic"></el-image>
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip label="操作面板" width="220px" align="center" fixed="right">
          <template #default="{ row }">
            <el-button @click="choosGood(row)" type="text">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :background="true" :current-page="queryForm.page" :layout="layout" :page-sizes="[20, 40, 60, 100]"
        :page-size="queryForm.limit" :total="total" @current-change="handleCurrentChange"
        @size-change="handleSizeChange"></el-pagination>
    </el-dialog>
  </div>

</template>

<script>
  import {
    bannerDetail,
    addList,
    updateBanner,
    getBannerList,
  } from '@/api/banner'
  import {
    goodsList
  } from '@/api/xgcApi'
  import {
    getUploadUrl
  } from '@/config'
  import uploadFile from '@/components/uploadFile'

  export default {
    name: 'BannerEdit',
    components: {
      uploadFile,
    },
    data() {
      return {
        total: 0,
        list: [],
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        shopListShow: false, //商品弹出层
        queryForm: { //商品分页
          page: 1,
          limit: 20,
          name: '',
          type: '',
        },
        isUp: '',
        actionUrl: getUploadUrl + '/index/index/file',
        form: {
          type: 0
        },
        rules: {
          sort: [{
            required: true,
            message: '请输入内容',
            trigger: 'blur'
          }],
          name: [{
            required: true,
            trigger: 'blur',
            message: '请输入排序'
          }],
          file: {
            required: true,
            trigger: 'blur',
            message: '请选择图片'
          },
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.listLoading = true
        const {
          data
        } = await goodsList(this.queryForm)
        this.list = data.list
        this.total = data.count
        const imageList = []
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
      choosGood(row){
        // 选择的商品
        this.shopListShow = false
        this.form.link = `/pages/shop_info/shop_info?id=${row.id}`
      },
      handleQuery() {
        // 商品列表查詢
        this.queryForm.page = 1
        this.fetchData()
      },
      shopclose() {
        this.shopListShow = false
      },
      shopOpen() {
        this.listLoading = true
        goodsList(this.queryForm).then(res => {
          this.list = res.data.list
          this.total = res.data.count
          this.shopListShow = true
          this.listLoading = false
        })

      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      // 排序输入校验
      handleInput() {
        // 移除非数字字符
        let value = this.form.sort.replace(/\D/g, '')
        // 如果输入值为0，则保留一个0
        if (value === '0' || value === '') {
          this.form.sort = '0'
        } else {
          // 移除前导零
          this.form.sort = String(parseInt(value))
        }
      },
      // 上传图片，处理路径\变成/
      uploadChange(e) {
        var strurl = e.join(',')
        strurl = strurl.replace('\\\\', '\/\/')
        strurl = strurl.replace('\\', '\/')
        strurl = strurl.replace('\\', '\/')
        this.form.file = strurl
      },
      // 页面初始化请求数据
      async showEdit(row) {
        if (!row) {
          this.title = '添加轮播图'
          this.isUp = false
          this.form.file = ''
        } else {
          this.title = '编辑轮播图'
          this.isUp = true
          const {
            data
          } = await bannerDetail({
            id: row.id
          })
          this.form = data
        }
        this.dialogFormVisible = true
      },
      // 关闭弹窗
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      // 提交 添加，编辑轮播图
      //判断是保存还是修改 isUp为true 则为修改 为false则为添加
      save() {
        if (this.isUp == false) {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              const {
                msg
              } = await addList(this.form)
              this.$baseMessage(msg, 'success')
              this.$emit('fetchDdata')
              this.close()
            } else {
              return false
            }
          })
        } else {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              const {
                msg
              } = await updateBanner(this.form)
              this.$baseMessage(msg, 'success')
              this.$emit('fetchDdata')
              this.close()
              this.$emit('fetch-data')
            } else {
              return false
            }
          })
        }
      },
    },
  }
</script>
<style scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
